import { Injectable, EventEmitter } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class SortTableService {
  public sort(sort: { key: string; value: string }, sortData) {
    let listOfDisplayData = [...sortData];
    return listOfDisplayData.sort((a, b) =>
      sort.value === 'ascend' ? (a[sort.key!] > b[sort.key!] ? 1 : -1) : b[sort.key!] > a[sort.key!] ? 1 : -1,
    );
  }
}
